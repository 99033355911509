import React from "react";
import SetIndiaBizLine from "./SetIndiaBiz.Line";
const SetIndiaBizPenalty = ({
  title,
  contentComponent,
  customContentClass = "",
  tableObject = [],
}) => {
  return (
    <div className="px-5 sm:px-10 md:px-20 py-5">
      <h3 className="font-semibold text-2xl md:text-4xl">{title}</h3>
      <SetIndiaBizLine />
      <div className={` ${customContentClass}`}>{contentComponent}</div>
      {tableObject.length > 0 && (
        <div class="flex flex-col mt-5">
          <div class="-m-1.5 overflow-x-auto">
            <div class="p-1.5 min-w-full inline-block align-middle">
              <div class="border border-red-100 rounded-lg overflow-hidden">
                <table class="min-w-full divide-y divide-red-100">
                  <thead class="bg-red-100">
                    <tr className="">
                      {Object.keys(tableObject[0])?.map((item, index) => (
                        <th
                          key={index.toString()}
                          scope="col"
                          class="px-6 py-3 text-start text-red-700 font-extrabold text-black uppercase"
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-red-100">
                    {tableObject?.map((item, index) => (
                      <tr className="">
                        {item &&
                          Object.values(item).map((obj, objIndex) => (
                            <td
                              key={objIndex.toString()}
                              class={`px-6 py-3 whitespace-nowrap w-fit text-wrap ${
                                objIndex == 1 ? "font-bold" : "font-medium"
                              }`}
                            >
                              {obj}
                            </td>
                          ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SetIndiaBizPenalty;
