export const API_END_POINT = `https://crm.golegalindia.com/golegal_india_mail/`; // live production endpoint
// export const API_END_POINT = `https://golegalindia.ashifsadiq.in/crm/api/`; // ashifsadiq testing development endpoint
// export const API_END_POINT = `http://localhost/golegal_india_mail_new/`; // local development endpoint
const testServers = ["localhost", "ashifsadiq", "192"];
export const isInDev = testServers.some((item) => API_END_POINT.includes(item));
export const RazorKey = isInDev
  ? "rzp_test_UpJr7FQeWcwYCt"
  : "rzp_live_DkJR2LDwzVy1Bm";
export const companyName = "Go Legal India";
export const registeredCompanyName = "PROVISTA LEGAL SERVICE PRIVATE LIMITED";
