import React from "react";

const SetIndiaBizLine = () => {
  return (
    <div className="flex justify-start my-3">
      <div className="rounded h-1 bg-red-500 w-full w-9/12" />
    </div>
  );
};

export default SetIndiaBizLine;
