import React from "react";
import SetIndiaBizLine from "./SetIndiaBiz.Line";
const SetIndiaBizRegistrationProcess = React.forwardRef(
  (
    {
      title,
      checkListArray = [
        {
          title: "Payment of Government Fee",
          description:
            "Once the application is filled and the documents are uploaded in the document section, the payment of the registration fee is made, and a reference number is generated. It is a temporary number to track the status of the application.",
        },
      ],
      customClass = "",
    },
    ref
  ) => {
    return (
      <section
        ref={ref}
        className={`px-5 sm:px-10 md:px-20 pt-10 ${customClass}`}
      >
        <h3 className="font-semibold text-left text-2xl md:text-4xl pt-7">
          {title}
        </h3>
        <SetIndiaBizLine />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {checkListArray.map((item, index) => (
            <div
              key={index.toString()}
              className="my-2 p-3 border rounded-lg shadow-md bg-white transition ease-in-out delay-100 hover:scale-110 duration-250"
            >
              <h4 className="font-bold font-bold mb-3">
                Step {index + 1} - {item.title}
              </h4>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </section>
    );
  }
);

export default SetIndiaBizRegistrationProcess;
