export function updateMetaDescription(newDescription) {
  if (newDescription) {
    const metaTag = document.querySelector('meta[name="description"]');
    if (metaTag) {
      metaTag.setAttribute("content", newDescription);
    } else {
      // If the meta tag doesn't exist, you can create it
      const newMetaTag = document.createElement("meta");
      newMetaTag.name = "description";
      newMetaTag.content = newDescription;
      document.head.appendChild(newMetaTag);
    }
  }
}
