import React from 'react'
import themeStyle from '../styles/theme.style'

function ProcessRoundCircleSection({ heading,process }) {

    return (
        <div className='md:m-20 m-10'>
            <div className='text-center font-bold text-4xl'>{heading}</div>
            <div className='flex md:justify-around mt-10 lg:flex-nowrap gap-x-10 lg:gap-0 flex-wrap justify-center'>
                {process?.map((item, index) => (
                    <div key={index} className='text-center mt-16 md:mt-10 md:w-[25%]'>
                        <div style={{ backgroundColor: themeStyle.COLOR_LIGHT_RED }} className='h-24 w-24 rounded-full mx-auto flex justify-center items-center'>{item.icon} </div>
                        <p className='font-bold text-lg mt-2'>{item.title}</p>
                        <p className='text-sm md:mt-5'>{item.description}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ProcessRoundCircleSection