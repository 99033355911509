import React, { useEffect, useState } from "react";
import TopBarHeader from "../../../../components/SetIndiaBiz/TopBarHeader";
import SetIndiaBizForm from "../../../../components/SetIndiaBiz/SetIndiaBizForm";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { API_END_POINT, companyName } from "../../../../Config/config";
import SetIndiaBizPlans from "../../../../components/SetIndiaBiz/SetIndiaBizPlans";
import SetIndiaBizOverview from "../../../../components/SetIndiaBiz/SetIndiaBizOverview";
import SetIndiaBizWidget from "../../../../components/SetIndiaBiz/SetIndiaBizWidget";
import MoneySvg from "../../../../assets/svg/money.svg";
import IndiaMap from "../../../../assets/images/IndiaMap.png";
import IndiaMapState from "../../../../assets/images/India-map-en.png";
import SetIndiaBizPenalty from "../../../../components/SetIndiaBiz/SetIndiaBizPenalty";
import SetIndiaBizLine from "../../../../components/SetIndiaBiz/SetIndiaBiz.Line";
import PageFaq from "../../../../components/PageFaq";
import TalkToExpertForm from "../../../../components/TalkToExpertForm";
const FssaiRenewal = () => {
  const location = useLocation();
  const reduxData = useSelector((state) => state);
  const [data, setData] = useState({});
  const [plansList, setPlansList] = useState([]);
  const [defaultPlan, setDefaultPlan] = useState({});
  const getPlanDetails = async () => {
    const API = `${API_END_POINT}Plans/getPlanDetails.php?plan=${encodeURI(
      location.pathname.replace(/^\/+/, "").replace(/\/+$/, "").split("/").pop()
    )}`;
    const response = await fetch(API);
    const resJson = await response.json();
    resJson.map((item) => {
      if (item.id == item.recommended_plan) {
        setDefaultPlan(item);
      }
    });
    setPlansList(resJson);
  };
  function formatService(companyName) {
    // Remove the leading slash if it exists
    if (companyName?.startsWith("/")) {
      companyName = companyName?.substring(1);
      companyName = `${companyName}`?.replace("/", "");
    }

    return companyName;
  }
  const formattedService = formatService("fssai-renewal");
  const getContent = async () => {
    if (reduxData?.webData) {
      document.title =
        reduxData?.webData[formattedService]?.title + " - " + companyName;
      setData(reduxData?.webData[formattedService] || {});
    }
  };
  const faqs = [
    {
      title: "When should I renew my FSSAI license?",
      content:
        "You should start the renewal process well before your current FSSAI license expires to ensure continuous compliance. The exact renewal timing depends on the type of license and its validity period.",
    },
    {
      title: "How do I renew my FSSAI license?",
      content: "FSSAI license renewal can typically be done online through Us",
    },
    {
      title: "What documents are required for FSSAI renewal?",
      content:
        "Common documents required for renewal include a filled and signed renewal application form (Form A/Form B), a copy of your current/previous FSSAI license, personal identification documents, and, in some cases, a Food Safety Management System (FSMS) plan.",
    },
    {
      title: "How much does it cost to renew an FSSAI license?",
      content:
        "Renewal fees vary based on the type of license and the duration of renewal. The specific fees can be found on the FSSAI website or by contacting your local FSSAI office.",
    },
  ];
  useEffect(() => {
    getPlanDetails();
  }, []);
  useEffect(() => {
    getContent();
  }, [reduxData]);
  return (
    <main className="cursor-default">
      {reduxData?.webData && (
        <div className="header-background px-5 sm:px-10 md:px-20 py-5 flex flex-col md:flex-row justify-between gap-x-10">
          <TopBarHeader
            title={reduxData?.webData[formattedService]?.title}
            subTitle={"Obtain FSSAI Registration Certificate"}
            description={
              "The FSSAI Registration or License is issued with a limited validity and has to be renewed before expiry. We help FBOs in getting their FSSAI License or Registration renewed in time to avoid late fees, penalties and suspension of business activities by the authorities."
            }
          />
          <TalkToExpertForm planPathName={defaultPlan?.service_type} heading={reduxData?.webData[formattedService]?.form_title} />
          {/* <SetIndiaBizForm
            formTitle={reduxData?.webData[formattedService]?.form_title}
            plan={defaultPlan}
          /> */}
        </div>
      )}
      {plansList.length > 0 && (
        <SetIndiaBizPlans
          plans={plansList}
          title="Our Professional Fee For FSSAI Registration of License"
        />
      )}
      <SetIndiaBizOverview
        title={`FSSAI Renewal for FBO Overview`}
        contentComponent={
          <>
            <p className="my-5">
              The Food Safety and Standards Authority of India (FSSAI) plays a
              pivotal role in ensuring the safety and quality of food products
              in India. With the growing concerns around food safety in the
              country, the authority has mandated businesses involved in the
              food industry to obtain the necessary FSSAI license or
              registration as per their eligibility and ensure their timely
              renewal.
            </p>
            <p className="my-5">
              The renewal process allows FSSAI to review and update the
              compliance status of an FBO, ensuring that their business
              continues to meet the necessary standards and regulations set
              forth by the Food Safety And Standards (Licensing and Registration
              of Food Business), Regulations 2011. Timely renewal of the license
              is crucial to avoid penalties, legal consequences, and disruptions
              in your business operations. Before we move further with the FSSAI
              renewal requirements and process, let’s first understand what is
              the eligibility for obtaining the initial license / registration.
            </p>
          </>
        }
        customContentClass={`text-justify`}
        footerComponent={
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <SetIndiaBizWidget
              title={
                <h3 className="font-bold text-xl text-center md:text-left">
                  FSSAI Registration
                </h3>
              }
              customComponentClass={`my-3 bg-red-100 py-2 rounded gap-4 items-center border border-red-500`}
              content={
                <p className="text-center md:text-left">
                  Food Registration is applicable for small businesses like
                  Dabbawalas, home-based kitchen, Home bakers, hawkers etc. or
                  petty PFO having turnover less than 12 Lakhs.
                </p>
              }
              icon={<img src={MoneySvg} className="w-40" alt="money" />}
            />
            <SetIndiaBizWidget
              title={
                <h3 className="font-bold text-xl text-center md:text-left">
                  State FSSAI License
                </h3>
              }
              customComponentClass={`my-3 bg-red-100 py-2 rounded gap-4 items-center border border-red-500`}
              content={
                <p className="text-center md:text-left">
                  Usually Medium-sized business having turnover more than 12
                  lacs p.a falls in this category.
                </p>
              }
              icon={<img src={IndiaMapState} className="w-36" alt="money" />}
            />
            <SetIndiaBizWidget
              title={
                <h3 className="font-bold text-xl text-center md:text-left">
                  Central FSSAI License
                </h3>
              }
              customComponentClass={`my-3 bg-red-100 py-2 rounded gap-4 items-center border border-red-500`}
              content={
                <p className="text-center md:text-left">
                  Food Central license is required mainly by businesses
                  operating in more than one state or FBOs having an annual
                  turnover of more than 20 crores.
                </p>
              }
              icon={<img src={IndiaMap} className="w-36" alt="money" />}
            />
          </div>
        }
      />
      <section>
        <SetIndiaBizPenalty
          title={"Penalty Under FSSAI"}
          contentComponent={
            <>
              <p className="my-3">
                Have you checked the validity of your FSSAI license or
                registration certificate? FSSAI does not grant licenses or
                registrations to FBOs with a lifetime validity. These are only
                valid for a limited duration and need to be renewed at regular
                intervals.
              </p>
              <p className="my-3">
                Up until now, both FSSAI licenses and registrations were granted
                for a period of 1 to 5 years based on the applicant’s choice and
                amount of application fees paid. However, a FSSAI order dated
                11th January 2023 has changed the validity provisions w.e.f 12th
                January, 2023 onwards.
              </p>
              <p className="my-3">
                The table below shows the validity of FSSAI License and
                Registration prescribed according to the new order.
              </p>
            </>
          }
          tableObject={[
            {
              "S.No": "1",
              "License / Registration": "FSSAI registration",
              "Duration of Validity":
                "1 to 5 Years (as per applicant’s choice)",
            },
            {
              "S.No": "2",
              "License / Registration": "FSSAI State License",
              "Duration of Validity": "1 year",
            },
            {
              "S.No": "3",
              "License / Registration": "FSSAI Central License",
              "Duration of Validity": "1 year",
            },
          ]}
        />
      </section>
      <section>
        <SetIndiaBizOverview
          title={"Process of FSSAI Renewal"}
          contentComponent={
            <>
              The process of FSSAI License or Registration renewal is completely
              online. You just need to fill out Form A for FSSAI Registration
              renewal and Form B for FSSAI License renewal, and upload all the
              documents required in their digital formats.
              {[
                "If there is any change in the FBO’s status, document, general information, and business activity since the grant of the expired food license/registration, a documentary proof of such change will be submitted with the renewal application.",
                "Certificate or Plan of Food Safety Management System being adopted (for units under Central Licensing, it has to be a certificate from accredited agencies).",
                "Copy of expired food license or registration certificate",
                "List of employees along with their medical fitness certificates.",
                "Name and details of technical staff in charge of the operation.",
              ].map((item, itemIndex) => (
                <div className="flex flex-row gap-3 m-2">
                  <span>{itemIndex + 1}.</span>
                  <span>{item}</span>
                </div>
              ))}
            </>
          }
        />
      </section>
      <section>
        <SetIndiaBizOverview
          title={"When to apply for FSSAI Renewal?"}
          contentComponent={
            <>
              <p className="my-3">
                As per the new order of FSSAI dated 11th January, 2023, w.e.f.
                from 12th January, 2023, FBOs can renew their licenses and
                registrations as early as 180 days prior to their expiry. This
                window extends right to the date of expiry, which means that the
                concerned license or registration can be issued anytime between
                this period without any late fees charged. The illustration
                below will help you understand better.
              </p>
              <p className="my-3">
                But what if your FSSAI license or registration expires? Well,
                you can still renew it after the expiry date up to a period of
                180 days, subject to the payment of late fees (Rs.100 per day of
                delay). After this period expires too, the license or
                registration will stand canceled or suspended and will be no
                longer applicable for renewal. The FBO will have to apply for a
                new license or registration to continue his food business
                activities.
              </p>
              <h3 className="font-bold underline">ILLUSTRATION 1:</h3>
              <p className="my-3 ml-2">
                Let’s suppose, Amba Sweets was granted a FSSAI State License on
                20th January, 2023. The validity of this license will only
                extend up to 20th January, 2024. Amba Sweets can file the
                application for its renewal from 20th July 2023, which is 180
                days prior to the expiry of the license, till the date of expiry
                which is 20th January 2024, without any late fees.{" "}
              </p>
              <p className="my-3 ml-2">
                After 20th January 2024, the license shall expire, but Amba
                Sweets will still have time to renew it with late fees until
                20th July 2024, which is until 180 days after the expiry. After
                20th July, 2024, the license will stand canceled or suspended
                and Amba Sweets will have to apply for a new license to continue
                its activities.{" "}
              </p>
              <p className="my-3 ml-2">
                Now, let’s compute the late fees imposed on the business. Let’s
                say Amba Sweets filed the renewal application on 20th March
                2024, which is 60 days after the expiry of the License.
              </p>
              <p className="my-3 ml-2">
                Number of Days of Delay = 60 days Late Fees per day = Rs.100{" "}
                <br />
                Total Late Fees = 100 x 60 = Rs.6,000 Note, that the late fees{" "}
                <br />
                will be charged over and above the normal fees. So, Total <br />
                Renewal Fees = Rs.2,000 + Rs.6,000 = Rs.8,000 <br />
              </p>
              <h3 className="font-bold underline">ILLUSTRATION 2:</h3>
              <p className="my-3 ml-2">
                Let’s suppose, Himalayan Fruit Sellers, a Street vendor, was
                granted FSSAI Registration on the 20th February, 2023 for a
                period of 2 years. So, the validity of the registration will
                extend up to 20th February, 2025, after which it will expire. To
                continue as a FSSAI registered vendor, Himalayan Fruit Sellers
                will have to file a renewal application within the due date.
                Let’s see what the due dates are!
              </p>
              <p className="my-3 ml-2">
                The application for renewal can be filed without any late fees
                anytime between 20th August, 2023, which is 180 days prior to
                the expiry of Registration, to 20th February, 2024, which is the
                exact date of expiry. After 20th February, 2024, the
                registration will expire, but it can still be renewed up to 180
                days after the expiry, that is up to 20th August, 2024, subject
                to the payment of late fees (Rs.100 for each day of Delay). If
                the renewal has not been done till this date also, the
                registration will be canceled or suspended and the vendor will
                have to obtain a fresh Registration again.
              </p>
              <p className="my-3 ml-2">
                Let’s compute the late fees imposed. Say, the renewal
                application was filed on 20th March, 2024, which is 30 days
                after the expiry of Registration. Then,
              </p>
              <p className="my-3 ml-2">
                Number of Days of Delay = 30 days <br />
                Late Fees per day = Rs.100 <br />
                Total Late Fees = 100 x 30 = Rs.3,000 <br />
                Note, that the late fees will be charged over and above the
                normal fees. <br />
                So, Total Renewal Fees = Rs.3,000 + Rs.100 = Rs.3,100 <br />
              </p>
            </>
          }
        />
      </section>
      <section>
        <SetIndiaBizOverview
          title={"Consequences on Non Renewal of FSSAI Registration/License"}
          contentComponent={
            <>
              <p className="my-3">
                If the renewal application is not filed before the expiry date,
                the license or registration shall expire. In such a situation,
                the Food Business Operator will have to file the renewal
                application within the next 180 days, that is within 180 days
                from the date of expiry, with a late fee of Rs.100 for each day
                of delay.
              </p>
              <p className="my-3">
                If the FBO misses this deadline too, his license or registration
                will be canceled or suspended and he will have to stop all
                business activities at the food unit. The Food Business Operator
                shall apply for a new food license or registration if it wants
                to restart its activities.
              </p>
              <p className="my-3">
                Designated Officers may order an inspection before renewal if
                considered necessary for reasons to be recorded in writing. If
                the Food Business Operator has a valid certificate from an
                agency accredited by the Food Authority or an accredited food
                safety auditor or any other organization notified by FSSAI, it
                does not require inspection.
              </p>
            </>
          }
        />
      </section>
      <section className={`px-5 sm:px-10 md:px-20 pt-10`}>
        <h3 className="font-semibold text-2xl md:text-4xl">{"FAQ"}</h3>
        <SetIndiaBizLine />
        <PageFaq list={faqs} AccordionOnly />
      </section>
      {/* <code>{JSON.stringify(reduxData?.webData[formattedService])}</code> */}
    </main>
  );
};

export default FssaiRenewal;
