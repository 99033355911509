import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import themeStyle from "../styles/theme.style";
import { LiaStarSolid } from "react-icons/lia";
import rightArrowIcon from "../assets/images/right-arrow.png";
import leftArrowIcon from "../assets/images/left-arrow.png";
import ASSOCIATED_1 from "../assets/images/Associated/ASSOCIATED_1.png";
import ASSOCIATED_2 from "../assets/images/Associated/ASSOCIATED_2.png";
import ASSOCIATED_3 from "../assets/images/Associated/ASSOCIATED_3.png";
import ASSOCIATED_4 from "../assets/images/Associated/ASSOCIATED_4.png";
import ASSOCIATED_5 from "../assets/images/Associated/ASSOCIATED_5.png";
import ASSOCIATED_6 from "../assets/images/Associated/ASSOCIATED_6.png";
import ASSOCIATED_7 from "../assets/images/Associated/ASSOCIATED_7.png";
const associatedArray = [
  { image: ASSOCIATED_1 },
  { image: ASSOCIATED_2 },
  { image: ASSOCIATED_3 },
  { image: ASSOCIATED_4 },
  { image: ASSOCIATED_5 },
  { image: ASSOCIATED_6 },
  { image: ASSOCIATED_7 },
];

const NextArrow = ({ onClick }) => {
  return (
    <div
      className="absolute top-[40%] cursor-pointer z-10 right-[-9%]  lg:right-[-5%]"
      onClick={onClick}
    >
      <img src={rightArrowIcon} height={32} width={32} />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div
      className="absolute top-[40%] cursor-pointer z-10 left-[-9%]  lg:left-[-5%]"
      onClick={onClick}
    >
      <img src={leftArrowIcon} height={32} width={32} />
    </div>
  );
};

const AssociatedCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      className="px-12 sm:px-16 py-16  w-full"
      style={{ backgroundColor: themeStyle.COLOR_LIGHT_GREY }}
    >
      <div style={{ margin: "" }}>
        <h1 className="text-black text-4xl font-bold mb-3 text-center">
          We Are Associated with
        </h1>
        <div className="mb-16 md:mx-16">
          <Slider {...settings}>
            {associatedArray.map((associated, index) => (
              <div key={index}>
                <div className="p-2 m-3 rounded-xl overflow-hidden">
                  <div className="flex justify-center">
                    <img
                      src={associated.image}
                      // alt={testimonial.name}
                      className="w-full items-center"
                      style={{
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default AssociatedCarousel;
