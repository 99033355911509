import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { pagePaths } from "../../Config/pagePaths";
import { Helmet } from "react-helmet";
import MetaManager from "../../components/MetaManager";
import { API_END_POINT } from "../../Config/config";
import SetIndiaBizForm from "../../components/SetIndiaBiz/SetIndiaBizForm";
import TalkToExpertForm from "../../components/TalkToExpertForm";

const Blog = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const blogSlug = location.pathname.split(`/${pagePaths.blog}/`)[1];
  const [blogData, setBlogData] = useState({});

  async function getBlog() {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ slug: blogSlug }),
    };
    const API = `${API_END_POINT}Blog/getBlog.php`;
    try {
      const response = await fetch(API, options);
      const data = await response.json();
      if (data.success === 0) {
        navigate("/");
      } else {
        setBlogData(data.data);
      }
    } catch (error) {
      console.error("Error fetching blog:", error);
      navigate("/"); // Redirect in case of fetch error
    }
  }

  useEffect(() => {
    getBlog();
  }, []);

  return (
    <article
      className={`w-full px-5 sm:px-20 ${blogData?.title ? "" : `hidden`}`}
    >
      <MetaManager
        title={blogData?.title}
        description={blogData?.description}
        author={blogData?.author_name}
        seo={blogData?.public}
        keywords={blogData?.keywords}
      />
      <header className="pt-10 flex flex-col gap-y-3">
        {blogData?.image_url ? (
          <figure>
            <img
              src={`${API_END_POINT}Blog/Assets/${blogData?.image_url}`}
              alt={blogData?.title || "Blog image"}
              className="w-full border rounded-xl"
            />
          </figure>
        ) : null}
        <h1 className="font-bold text-3xl">{blogData?.title}</h1>
        <p>
          <strong>By {blogData?.author_name}</strong> | Published on{" "}
          {blogData?.publish_date?.split(" ")[0]}
        </p>
      </header>
      <main
        className="py-10"
        dangerouslySetInnerHTML={{ __html: blogData?.content }}
      />
      <div className="w-full flex justify-center">
        <TalkToExpertForm
          planPathName={blogData?.plan?.service_type}
          heading={blogData?.title}
        />
      </div>
    </article>
  );
};

export default Blog;
