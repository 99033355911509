import React, { useEffect } from "react";
import themeStyle from "../../styles/theme.style";
import AboutusImg from "../../assets/images/about-us.png";
import { companyName, registeredCompanyName } from "../../Config/config";
import { updateMetaDescription } from "../../Config/functions";

function AboutUs() {
  useEffect(() => {
    document.title = "About US | " + companyName;
    updateMetaDescription("About US | " + companyName);
  }, []);
  return (
    <div className="px-6 md:px-10 py-16  w-full">
      <div className="lg:flex items-center flex-col md:flex-row">
        <div className="lg:w-[50%] mt-5 flex justify-center">
          <img
            className="rounded-3xl"
            height={600}
            width={600}
            src={AboutusImg}
          />
        </div>
        <div className="lg:w-[50%] mb-6 md:mb-0 md:mx-10 mt-10">
          <h1 className="text-black text-4xl font-bold">About Us</h1>
          <div className="mt-5">
            <p style={{ color: themeStyle.COLOR_DARK_GREY }}>
              {registeredCompanyName} is registered under
              the Companies Act, 2013. We help businesses like individuals,
              companies and etc with very affordable and quality services. All
              at one place.
            </p>
            <br />
            <p style={{ color: themeStyle.COLOR_DARK_GREY }}>
              Our company vision is to solve the customer issue smoothly, so
              that trust is built between our clients and us. “We assure our
              clients with the best provided service with a good quality, to
              create better everyday life for people”
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
