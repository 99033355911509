import React from "react";

const SetIndiaBizWidget = ({
  icon,
  title,
  content,
  customComponentClass = "",
}) => {
  return (
    <div
      className={`${customComponentClass} flex flex-col md:flex-row p-4 rounded-xl gap-4 items-center shadow-md`}
    >
      {icon && <div className="my-2 md:my-0">{icon}</div>}
      <div>
        {title}
        {content}
      </div>
    </div>
  );
};

export default SetIndiaBizWidget;
