export const pagePaths = {
  privateLimitedCompany: "private-limited-company",
  llpRegistration: "llp-registration",
  onePersonCompany: "one-person-company",
  ngoRegistration: "ngo-registration",
  fssaiRegistration: "fssai-registration",
  fssaiRenewal: "fssai-renewal",
  gstRegistration: "gst-registration",
  gstReturnFiling: "gst-return-filing",
  incomeTaxFiling: "income-tax-filing",
  isoCertificate: "iso-certificate",
  importExport: "import-export",
  trademarkRegistration: "trademark-registration",
  trademarkObjection: "trademark-objection",
  trademarkHearing: "trademark-hearing",
  trademarkOpposition: "trademark-opposition",
  copyrightRegistration: "copyright",
  // depending Pages
  contactUs: "contact-us",
  aboutUs: "about-us",
  privacyPolicy: "privacy-policy",
  refundPolicy: "refund-policy",
  disclaimer: "disclaimer",
  termsConditions: "terms-conditions",
  // required pages
  choosePlan: "choose-plan",
  payment: "payment",
  receipt: "receipt",
  thankYou: "thankyou",
  blog:'blog'
};

export const isPageEnabled = {
  privateLimitedCompany: true,
  llpRegistration: true,
  onePersonCompany: true,
  ngoRegistration: true,
  fssaiRegistration:true,
  fssaiRenewal:true,
  gstRegistration: true,
  gstReturnFiling: true,
  incomeTaxFiling: true,
  isoCertificate: true,
  importExport: true,
  trademarkRegistration: true,
  trademarkObjection: true,
  trademarkHearing: true,
  trademarkOpposition: true,
  copyrightRegistration: true,
  // required pages
  choosePlan: true,
  payment: true,
  receipt: true,
  contactUs: true,
  aboutUs: true,
  privacyPolicy: true,
  refundPolicy: true,
  disclaimer: true,
  thankYou: !true,
  termsConditions: true,
};
export const serviceTitles = {
  "Start Business": [
    "private-limited-company",
    "llp-registration",
    "one-person-company",
    "ngo-registration",
    "iso-certificate",
    "import-export",
  ],
  "Tax & Compliance": [
    "gst-registration",
    "gst-return-filing",
    "income-tax-filing",
  ],
  "Filing & Drafting": [
    "fssai-registration",
    "fssai-renewal",
  ],
  "Trademark & IP": [
    "trademark-registration",
    "trademark-objection",
    "trademark-hearing",
    "trademark-opposition",
    "copyright",
  ],
};
export const navTitles = {
  privateLimitedCompany: "Private Limited Company",
  llpRegistration: "LLP Registration",
  onePersonCompany: "One Person Company",
  ngoRegistration: "NGO Registration",
  fssaiRegistration: "FSSAI Registration",
  fssaiRenewal: "FSSAI Renewal",
  gstRegistration: "GST Registration",
  gstReturnFiling: "GST Return Filing",
  incomeTaxFiling: "Income Tax Filing",
  isoCertificate: "ISO Certificate",
  importExport: "Import Export",
  trademarkRegistration: "Trademark Registration",
  trademarkObjection: "Trademark Objection",
  trademarkHearing: "Trademark Hearing",
  trademarkOpposition: "Trademark Opposition",
  copyrightRegistration: "Copyright",
  choosePlan: "Choose Plan",
  payment: "Payment",
  receipt: "Receipt",
  contactUs: "Contact Us",
  aboutUs: "About Us",
  privacyPolicy: "Privacy Policy",
  refundPolicy: "Refund Policy",
  disclaimer: "Disclaimer",
  thankYou: "Thank You",
  termsConditions: "Terms & Conditions",
};
