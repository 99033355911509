import React from 'react'
import TopBannerSection from '../../components/TopBannerSection'
import ProcessRoundCircleSection from '../../components/ProcessRoundCircleSection'
import themeStyle from '../../styles/theme.style'
import { TfiHeadphoneAlt } from 'react-icons/tfi'
import { HiDocumentArrowDown } from 'react-icons/hi2'
import { FaCheck } from 'react-icons/fa'
import CustomTabs from '../../components/CustomTabs'
import { ISOBenefit, ISODocumentsRequired, ISOOverview, ISORequirement, ISOTypes } from '../Lisence/content/ISOTabContent'
import PageFaq from '../../components/PageFaq'
import OverviewContent from '../../components/OverviewContent'
import BackgroundImage from "../../assets/images/License/isoTest.jpg"
import OverViewImage from "../../assets/images/License/iso overview.jpg";
import { useLocation } from 'react-router-dom'
function ISOCertificate() {
  const topImageUrl = "https://st3.depositphotos.com/30987588/34891/v/450/depositphotos_348913014-stock-illustration-people-in-cozy-cafe-coffee.jpg";

  const data = ['Get Dedicated ISO Expert', 'Application Done in Just 15 Mins', 'Apply from Anywhere in India', '5/5 Rating ISO Services']

  const process = [
    { title: 'Reach out to our Experts', icon: <TfiHeadphoneAlt size={46} color={themeStyle.COLOR_RED} />, description: 'We have the best business experts who can resolve all your queries' },
    { title: 'Provide all the required documents', icon: <HiDocumentArrowDown size={46} color={themeStyle.COLOR_RED} />, description: 'Provide all the documents. Our team will initiate the paperwork on your behalf' },
    { title: 'Get your ISO Certification done', icon: <FaCheck size={46} color={themeStyle.COLOR_RED} />, description: 'ISO Certification is completed and the certificates will be sent to you.' },
  ]

  const tabs = [
    // { title: "Overview", content: <ISOOverview /> },
    { title: "Benefit", content: <ISOBenefit/> },
    { title: "Requirement", content: <ISORequirement/> },
    { title: "Types", content: <ISOTypes/>},
    { title: "Document required", content: <ISODocumentsRequired/> },
  ];

  const faqs = [
    {
      title: 'Can I use ISO Logo ?',
      content: 'Only ISO, ISO members, and ISO technical committees (TCs) are allowed to use the ISO logo and ISO short name in accordance with ISO Policies.',
    },
    {
      title: 'Will i get a certificate in the pre-audit stage ?',
      content: 'No, at the pre-audit stage you will not get a certificate. The auditor will only assess your business system and procedures to make suggestions so that you can work towards making your company ISO compliant.',
    },
    {
      title: 'Does an ISO certification work as permit or License ?',
      content: 'An ISO certification only certifies standards, it has got nothing to do with permits and licences. Even though you might have an ISO certification, you still need government and authority permission, permits, and licences to work your business.',
    },
  ];
  const location = useLocation();
  return (
    <div>
      <TopBannerSection location={location.pathname} backgroundCover={true} heading={'Apply for ISO Certification'} lineThrough={'₹4499'} boldNumber={'₹1999'} list={data ?? []} />
      <ProcessRoundCircleSection heading={'How To Do Your ISO Certification Through "GLI"'} process={process} />
      <OverviewContent OverViewImage={OverViewImage} content={ <ISOOverview /> }/>
      <CustomTabs tabs={tabs} />
      <PageFaq list={faqs}/>
    </div>
  )
}

export default ISOCertificate