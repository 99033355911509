import React, { useState } from "react";
import themeStyle from "../styles/theme.style";

function CustomTabs({ tabs }) {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <div className="flex flex-col  lg:flex-nowrap flex-wrap md:m-16 lg:mx-24 lg:my-16 m-10 ">
        <div className="md:flex lg:flex-nowrap flex-wrap justify-content-around">
          {tabs.map((tab, index) => (
            <button
              style={{
                borderColor:
                  activeTab === index ? themeStyle.COLOR_RED : "transparent",
                backgroundColor:
                  activeTab === index
                    ? themeStyle.COLOR_LIGHT_RED
                    : "transparent",
              }}
              key={index}
              onClick={() => setActiveTab(index)}
              className={`flex-1 pb-2 border-b-4 font-semibold py-2 px-3 rounded-lg ${
                activeTab === index
                  ? ""
                  : ""
              } text-gray-700`}
            >
              {tab.title}
            </button>
          ))}
        </div>

        <div
          style={{ border: `1px solid ${themeStyle.COLOR_RED}` }}
          className="mt-4"
        ></div>
        <div className="mt-4">
          <p>{tabs[activeTab].content}</p>
        </div>
      </div>
    </>
  );
}

export default CustomTabs;
