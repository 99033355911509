import React, { useEffect, useState } from "react";
import SetIndiaBizLine from "./SetIndiaBiz.Line";

const SetIndiaBizDocumentsRequired = ({
  title = undefined,
  description = undefined,
  documentsType = undefined,
  documentsTypeList = [],
  selectedDocumentsTypeIndex = undefined,
  documents = [],
  notes = "",
}) => {
  const [selectedDocumentType, setSelectedDocumentType] = useState(undefined);
  useEffect(() => {
    if (selectedDocumentsTypeIndex) {
      setSelectedDocumentType(documentsTypeList[selectedDocumentsTypeIndex]);
    } else {
      setSelectedDocumentType(documentsTypeList[0]);
    }
  }, []);
  return (
    <div className="px-5 sm:px-10 md:px-20 py-5">
      <h2 className="font-semibold text-left text-2xl md:text-4xl pt-7">
        Documents Required {documentsType && `for ${documentsType} `} In India
      </h2>
      {(title || description) && <SetIndiaBizLine />}
      {description && <p>{description}</p>}
      <div className="flex flex-col sm:flex-row justify-center py-3 gap-y-5 items-center md:mx-32">
        <label for="documentsType" className="font-medium w-full">
          List of Documents {documentsType && `for ${documentsType} `}By
        </label>
        <select
          id="documentsType"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5"
          onChange={(e) => setSelectedDocumentType(e.target.value)}
        >
          {documentsTypeList.map((item, index) => (
            <option
              key={index.toString()}
              selected={selectedDocumentType == item}
              value={item}
            >
              {item}
            </option>
          ))}
        </select>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {documents.map(
          (document, documentIndex) =>
            document?.for == selectedDocumentType &&
            document.list?.map((list, listIndex) => (
              <div className="border-grey-600 border max-h-fit rounded-lg shadow-md bg-white">
                <h3 className="font-bold text-base text-white text-center bg-red-600 py-2 rounded-tl-lg rounded-tr-lg">
                  {list.title}
                </h3>
                {list?.required &&
                  list?.required?.map((required, requiredIndex) => (
                    <li className="list-none py-1 px-3">
                      {requiredIndex + 1}. {required}
                    </li>
                  ))}
              </div>
            ))
        )}
      </div>
      {documents.map(
        (document, documentIndex) =>
          document?.for == selectedDocumentType && (
            <p className="my-4">
              <span className="font-bold">Note: </span>
              {document.notes}
            </p>
          )
      )}
    </div>
  );
};

export default SetIndiaBizDocumentsRequired;
