import React, { useState } from "react";
import SetIndiaBizLine from "../SetIndiaBiz.Line";
import SetIndiaBizHelpsContainer from "./SetIndiaBizHelpsContainer";
import ScrollTrigger from "react-scroll-trigger";

const SetIndiaBizHelpsBusiness = ({ customClass, array = [] }) => {
  const [isTriggered, setIsTriggered] = useState(true);
  return (
    <div className={`px-5 sm:px-10 md:px-20 pt-10 ${customClass}`}>
      <h3 className="font-semibold text-center text-2xl md:text-4xl pt-7">
        We Helped Businesses
      </h3>
      <SetIndiaBizLine />
      <ScrollTrigger
        onEnter={() => setIsTriggered((e) => !e)}
        onExit={() => setIsTriggered((e) => !e)}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-20 gap-y-5">
          {array.map((item, index) => (
            <SetIndiaBizHelpsContainer
              count={item.count}
              image={item.image}
              title={item.title}
              isTriggered={isTriggered}
            />
          ))}
        </div>
      </ScrollTrigger>
    </div>
  );
};

export default SetIndiaBizHelpsBusiness;
