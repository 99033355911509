import React from "react";
import SetIndiaBizLine from "./SetIndiaBiz.Line";

const SetIndiaBizOverview = ({
  title,
  contentComponent,
  customContentClass,
  footerComponent,
  customComponentClass,
}) => {
  return (
    <div className={`px-5 sm:px-10 md:px-20 pt-10`}>
      <h3 className="font-semibold text-2xl md:text-4xl">{title}</h3>
      <SetIndiaBizLine />
      <div className={` ${customContentClass}`}>{contentComponent}</div>
      <div className={` ${customComponentClass}`}>{footerComponent}</div>
    </div>
  );
};

export default SetIndiaBizOverview;
